<template>
  <CFooter :fixed="false">
    <div>
      
    </div>
    <div class="mfs-auto">
      
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

//var adm = require('./menu_adm.json');
var sa  = require('./menu_sa.json');

var strroles = localStorage.getItem('roles');
var roles    = strroles.split(","); //console.log(roles);
var menus    = [sa];  var temp = []; 
//var checkRoleMenu = ["Settings","Reports"];

for (var item of menus){   
  var row = item._children;
  for (var key in row){
       if(!roles.includes(row[key].role) && row[key].role !== "ALL"){
          menus[0]._children[key] =[];
       }

      //if(checkRoleMenu.includes(row[key].name)){  
          var list = row[key].items;
          for (var key2 in list){ 
              var mrole = list[key2].role;  
              if(roles.includes(mrole) || mrole === "ALL"){
                temp.push(menus[0]._children[key].items[key2]);                               
              } 
          } 
          menus[0]._children[key].items = [];            
          for (var key3 in temp){
              menus[0]._children[key].items.push(temp[key3]);
          } temp = [];
      //}
  }                 
}

var menu = menus[0];
export default [menu]
<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <p v-show="seen">Is it Idle? - {{ isIdle }}</p>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import AuthService from '@/api/AuthService.js';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data () {
      return {       
        seen: false,        
      }
    },
  created () {

  },
  computed: {
    isIdle() {
      if(this.$store.state.idleVue.isIdle){
        this.$store.dispatch('logout');       
        localStorage.removeItem('isAuthenticated');
        this.$router.push("/auth/login").catch(()=>{});
      }
      return this.$store.state.idleVue.isIdle;
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
